import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { scaleLinear } from 'd3-scale';


const LineGraph = ({ data }) => {
    // Find the minimum and maximum values in the data
    const minValue = Math.min(...data.map(d => Math.min(...d.data.map(item => item.y))));
    const maxValue = Math.max(...data.map(d => Math.max(...d.data.map(item => item.y))));

    // Create a color scale
    const colorScale = scaleLinear()
        .domain([minValue, (minValue + maxValue) / 2, maxValue])
        .range(['dodgerblue', 'peachpuff', 'darkorange']);

    return (
<ResponsiveLine
    data={data}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    xScale={{
        type: 'time',
        format: '%Y-%m-%dT%H:%M:%S.%f',
        precision: 'second',
    }}
    xFormat="time:%Y-%m-%d"
    curve="monotoneX"
    axisBottom={{
        format: '%b %d',
        tickValues: 'every month', // Change this line
        legend: 'time scale',
        legendOffset: -12,
    }}
    colors={({ id, data }) => colorScale(Math.max(...data.map(d => d.y)))} // Use the color scale
    useMesh={true} // Enable the mesh
    tooltip={({ point }) => ( // Add tooltip
        <div>
            <strong>{point.serieId}</strong>
            <br />
            {`date: ${point.data.xFormatted}`}
            <br />
            {`reviews: ${point.data.y}`}
        </div>
    )}
/>
    );
};
const transformTimeSeriesData = (data) => {
    // console.log('Timeseries data', data)
    // Group data by name
    const groupedData = data.reduce((acc, curr) => {
        if (!acc[curr.name]) {
            acc[curr.name] = [];
        }
        acc[curr.name].push(curr);
        return acc;
    }, {});
  
    // Transform data to fit @nivo/line format
    const transformedData = Object.keys(groupedData).map((key) => ({
        id: key,
        data: groupedData[key].map((item) => ({
            x: item.timestamp,
            y: item.rating_count,
        })),
    }));
  
    return transformedData;
  };


function LineGraphContainer({ data }) {
    const [timeSeries, setTimeSeries] = useState([]);

    useEffect(() => {
        if (data) {
            const transformedData = transformTimeSeriesData(data);
            setTimeSeries(transformedData);
        }
    }, [data]);

    return <LineGraph data={timeSeries} />;
};

export default LineGraphContainer