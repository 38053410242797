import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import ReviewTable from './components/ReviewTable';  // adjust the path as needed
import Navbar from './components/Navbar';
import BarGraphContainer from './components/BarGraph';
import PieGraphContainer from './components/PieGraph';
import ExploreParentComponent from './components/ExploreParentComponent';
import TrackParentComponent from './components/TrackParentComponent';
import RankParentComponent from './components/RankParentComponent';
import TrafficParentComponent from './components/TrafficParentComponent';
import ChangeLogParentComponent from './components/ChangeLogParentComponent';


import WeekReviewParentComponent from './components/WeekReviewParentComponentV3';
import BusinessComparisonDashboard from './components/CompareWeekV3';

import YearlyReviewDashboard from './components/YearReviewParentComponent';
import BusinessComparisonYearDashboard from './components/CompareYear';


import Login from './components/Login';
import { ChakraProvider } from '@chakra-ui/react';
import NotFoundPage from './NotFoundPage'; // Make sure to create this component
import { useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button } from '@chakra-ui/react';
import { FaHistory } from 'react-icons/fa';
import { Flex, Text, Box } from '@chakra-ui/react';



// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD9FZAwhdtUBMS7e2HLli92L59-9KFRIK0",
  authDomain: "sub40-411602.firebaseapp.com",
  projectId: "sub40-411602",
  storageBucket: "sub40-411602.appspot.com",
  messagingSenderId: "390182094470",
  appId: "1:390182094470:web:894433eb7c550d22423307"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


function useIsAuthenticated() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });
  }, []);

  return isAuthenticated;
}

function WelcomeDialog() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      onOpen();
      localStorage.setItem('hasVisited', 'true');
    }
  }, [onOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
      <ModalHeader>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <img src="./output.png" alt="Localytics Logo" style={{ marginRight: '10px', width: '100px', height: '100px' }} />
</div>
  Welcome to Localytics!
</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Thank you for signing onto our beta.</p>
          <br></br>
          <Flex align="center" justify="space-between">
            <Text>Don't forget to check the <Box as="span" display="inline-flex" alignItems="center"><b>Changelog</b> &nbsp;<FaHistory mr={2}/></Box> for updates and future enhancements</Text>
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button colorScheme="blue" onClick={onClose}>
            Ok!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function withAuthCheck(Component, redirectTo = '/login') {
  return function AuthenticatedComponent(props) {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsAuthenticated(true);
        } else {
          navigate(redirectTo);
        }
      });
    }, [navigate]);

    if (isAuthenticated === null) {
      return null; // or a loading spinner
    }

    return <Component {...props} />;
  };
}



// reroute from home to explore
function BaseRoute() {
  let navigate = useNavigate();
  useEffect(() => {
    navigate('/week-in-review');
  }, [navigate]);

  return null;
}

function Disclaimer() {
  return (
    <div style={{ 
      position: 'fixed', 
      bottom: '0', 
      width: '100%', 
      textAlign: 'center', 
      padding: '10px', 
      background: '#f9f9f9' 
    }}>
      <p style={{ fontSize: '0.8em' }}>This is a demo. All data is for demonstration purposes only.</p>
    </div>
  );
}

function Home() {
  return (
    <ExploreParentComponent />
  )
}

function Track() {
  return (
    <TrackParentComponent />
  );
}

function Rank() {
  return (
    <RankParentComponent />
  );
}

function Traffic(){
  return (
    <TrafficParentComponent />
  );
}

function ChangeLog(){
  return (
    <ChangeLogParentComponent />
  );
}

function WeekInReview(){
  return (
    <WeekReviewParentComponent />
  );
}

function YearInReview(){
  return (
    <YearlyReviewDashboard  />
  );
}

function CompareWeekPage(){
  return(
    <BusinessComparisonDashboard />
  )
}

function CompareYearPage(){
  return(
    <BusinessComparisonYearDashboard />
  )
}

const AuthenticatedHome = withAuthCheck(Home);
const AuthenticatedTrack = withAuthCheck(Track);
const AuthenticatedRank = withAuthCheck(Rank);
const AuthenticatedTraffic = withAuthCheck(Traffic);
const AuthenticatedChangeLog = withAuthCheck(ChangeLog);
const AuthenticatedWeekInReview = withAuthCheck(WeekInReview);
const AuthenticatedCompareWeekPage = withAuthCheck(CompareWeekPage);
const AuthenticatedBaseRoute = withAuthCheck(BaseRoute, '/explore');
const AuthenticatedYearInReview = withAuthCheck(YearInReview);
const AuthentictedCompareYearPage = withAuthCheck(CompareYearPage);

function App() {
  const isAuthenticated = useIsAuthenticated();
  return (
    <ChakraProvider>
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
      <Route path="/explore" element={<AuthenticatedHome />} />
      <Route path="/track" element={<AuthenticatedTrack />} />
      <Route path="/rank" element={<AuthenticatedRank />} />
      <Route path="/traffic" element={<AuthenticatedTraffic />} />
      <Route path="/" element={<AuthenticatedBaseRoute />} />
      <Route path="/login" element={<Login />} />
      <Route path="/changelog" element={<AuthenticatedChangeLog />} /> 
      <Route path="/week-in-review" element={<AuthenticatedWeekInReview />} /> 
      <Route path="/year-in-review" element={<AuthenticatedYearInReview />} /> 
      <Route path="/compare-week" element={<AuthenticatedCompareWeekPage />} />
      <Route path="/compare-year" element={<AuthentictedCompareYearPage />} />
      {/* Catch-all route for 404 Not Found page */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
      </div>
      {isAuthenticated && <WelcomeDialog />}
      <Disclaimer /> {/* Add the disclaimer at the bottom */}
    </Router>
    </ChakraProvider>
  );
}

export default App;