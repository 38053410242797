import React, { useEffect, useState } from 'react';
import { Grid, Box, Heading, Button, Flex} from "@chakra-ui/react";
import ReviewTable from './ReviewTable'; 
import PieGraphContainer from './PieGraph';
import BarGraphContainer from './BarGraph';
import LineGraphContainer from './LineGraph';
import { getReviews } from '../services/reviewService';  // adjust the path as needed
import { Spinner } from "@chakra-ui/react"; // Import Spinner from Chakra UI

function TrackParentComponent () { 
    const [reviews, setReviews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedData, setSelectedData] = useState([]);
    const [timeSeriesData, setTimeSeriesData] = useState([]);
    const [sortOrders, setSortOrders] = useState({
      rating_count: 'asc',
      rating_avg: 'asc',
      difference: 'asc',
      monthly_share: 'asc',
      net_growth: 'asc'
    });
    const sortData = (data, method, order) => {
        if (order === 'random') {
          return [...data].sort(() => Math.random() - 0.5);
        }
        return [...data].sort((a, b) => order === 'asc' ? a[method] - b[method] : b[method] - a[method]);
      }
  
      const handleSort = (method) => {
        const orders = ['asc', 'desc', 'random'];
        const currentOrderIndex = orders.indexOf(sortOrders[method]);
        const nextOrderIndex = (currentOrderIndex + 1) % orders.length;
        setSortOrders(prevState => ({
          ...prevState,
          [method]: orders[nextOrderIndex]
        }));
      }
      
      useEffect(() => {
          getReviews().then(data => {
            // am i doing this at the right time? -- feels a little slow
            setTimeSeriesData(data);
            // calculate the per month change and the month to month difference
            const sortedData = data.sort((a, b) => b.rating_count - a.rating_count);
            const topTwoPerName = sortedData.reduce((acc, review) => {
              if (!acc[review.name]) {
                acc[review.name] = [review];
              } else {
                const maxReview = acc[review.name][0];
                const secondMaxReview = acc[review.name][1];
                if (!secondMaxReview || review.rating_count > secondMaxReview.rating_count) {
                  if (review.rating_count < maxReview.rating_count) {
                    acc[review.name][1] = review;
                  } else if (review.rating_count > maxReview.rating_count) {
                    acc[review.name][1] = maxReview;
                    acc[review.name][0] = review;
                  }
                }
              }
              return acc;
            }, {});
            
            const reviewsWithDifference = Object.values(topTwoPerName).map(([first, second]) => {
              if (first && second) {
                const date1 = new Date(first.timestamp);
                const date2 = new Date(second.timestamp);
                const diffDays = Math.abs(date2 - date1) / (1000 * 60 * 60 * 24);
                const diffMonths = diffDays / 30.44; // calculate the difference in months
                const difference = first.rating_count - second.rating_count; // calculate the difference
                first.difference = difference; // set difference per datapoint
                first.monthly_gain = difference / diffMonths; // set monthly_gain per datapoint
                first.net_growth = (difference / first.rating_count) * 100; // calculate and set net_growth per datapoint
              } else {
                first.difference = 0;
                first.monthly_gain = 0;
                first.net_growth = 0;
              }
              return first;
            });
  
            // Calculate the sum of monthly_gain for all businesses
            const totalMonthlyGain = reviewsWithDifference.reduce((sum, review) => sum + review.monthly_gain, 0);
  
            // Calculate the monthly_share for each business
            reviewsWithDifference.forEach(review => {
              review.monthly_share = (review.monthly_gain / totalMonthlyGain) * 100;
            });
  
            setReviews(reviewsWithDifference);
            setSelectedData(reviewsWithDifference);
            setIsLoading(false);
            // console.log(reviewsWithDifference);
          });
        }, []);
  
        const handleRowSelection = (selectedRowData) => {
          setSelectedData(selectedRowData.selectedRows.length > 0 ? selectedRowData.selectedRows : reviews);
        } 
  
      if (isLoading) {
          return (
              <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                  <Spinner size="xl" />
              </Box>
          ); // Render Spinner if isLoading is true
      }
      return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" height="80vh">
          <Heading as="h2" size="md">Track Reviews Over Time</Heading>
          <LineGraphContainer data={timeSeriesData} />
        </Box>
      );
}



export default TrackParentComponent;