import React from 'react';
import { Box, Text, Icon, VStack, useColorModeValue } from "@chakra-ui/react";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";

const RankingListComponent = ({ title, data, valueKey, side, unit }) => {
  const bg = useColorModeValue("gray.50", "gray.700");
  const hoverBg = useColorModeValue("gray.100", "gray.600");

  const isTop = side === 'top';
  const color = isTop ? "green" : "red";
  const IconComponent = isTop ? ArrowUpIcon : ArrowDownIcon;

  return (
    <VStack align="start" spacing={4} p={4} bg={bg} borderRadius="md" boxShadow="md">
      <Text fontSize="2xl" color="black">{title}</Text>
      {data.map((item, index) => {
        const value = item[valueKey];
        const displayValue = Number.isInteger(value) ? value : parseFloat(value).toFixed(1);
        const colorIntensity = index === 0 ? "500" : index < data.length / 2 ? "400" : "300";

        return (
          <Box key={index} w="full" p={2} bg={bg} borderRadius="md" _hover={{ bg: hoverBg }}>
            <Text fontSize={`${1.2 - index * 0.1}em`} color={`${color}.${colorIntensity}`}>
              <Text as="span" fontWeight="bold">{item.name}</Text>: {displayValue} {unit}
              <Icon as={IconComponent} ml={2} />
            </Text>
          </Box>
        );
      })}
    </VStack>
  );
};

export default RankingListComponent;