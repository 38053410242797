import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Flex, Grid, Heading, Text, Badge, Icon, Spinner, VStack, ButtonGroup, Button } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, LineChart, Line, Cell } from 'recharts';
import { StarIcon } from '@chakra-ui/icons';
import getWeekData from '../services/weekService.js';
import { useNavigate } from 'react-router-dom';

const BusinessComparisonDashboard = () => {
  const [allWeekData, setAllWeekData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDateRanges, setSelectedDateRanges] = useState([]);
  const [availableWeeks, setAvailableWeeks] = useState([]);
  const [businessColors, setBusinessColors] = useState({});
  const navigate = useNavigate();

  const colors = ['#4299E1', '#48BB78', '#ED8936', '#9F7AEA', '#F56565', '#4FD1C5', '#ED64A6'];

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getWeekData();
      setAllWeekData(data);
      
      // Sort weeks by date (assuming sunday is in 'YYYY-MM-DD' format)
      const sortedWeeks = data.sort((a, b) => new Date(b.sunday.replace(/-/g, "/")) - new Date(a.sunday.replace(/-/g, "/")));
      
      // Create date range options
      const dateRangeOptions = sortedWeeks.map((week, index) => {
        const endDate = new Date(week.sunday.replace(/-/g, "/"));
        const startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 6);
        return {
          value: index,
          label: `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`,
          weeks: [week]
        };
      });

      // Add options for last 4 and 8 weeks
      [4, 8].forEach(weekCount => {
        if (sortedWeeks.length >= weekCount) {
          const endDate = new Date(sortedWeeks[0].sunday.replace(/-/g, "/"));
          const startDate = new Date(sortedWeeks[Math.min(weekCount - 1, sortedWeeks.length - 1)].sunday);
          startDate.setDate(startDate.getDate() - 6);
          dateRangeOptions.unshift({
            value: `last${weekCount}weeks`,
            label: `Last ${weekCount} Weeks (${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()})`,
            weeks: sortedWeeks.slice(0, weekCount)
          });
        }
      });

      setAvailableWeeks(dateRangeOptions);
      
      // Set default selection to the most recent week
      const defaultSelection = [dateRangeOptions[dateRangeOptions.findIndex(option => typeof option.value === 'number')]];
      setSelectedDateRanges(defaultSelection);
      
      const sortedData = aggregateBusinessData(defaultSelection.flatMap(range => range.weeks));
      setBusinessData(sortedData);

      // Assign colors to all businesses
      const allBusinesses = new Set(sortedData.map(business => business.name));
      const newBusinessColors = {};
      Array.from(allBusinesses).forEach((business, index) => {
        newBusinessColors[business] = colors[index % colors.length];
      });
      setBusinessColors(newBusinessColors);

      setSelectedBusinesses(sortedData.slice(0, 3).map(business => business.name));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const aggregateBusinessData = useCallback((weeks) => {
    const aggregatedData = {};
    weeks.forEach(week => {
      week.data.forEach(business => {
        if (!aggregatedData[business.name]) {
          aggregatedData[business.name] = { ...business, data: { ...business.data } };
        } else {
          const existing = aggregatedData[business.name].data;
          const current = business.data;
          existing.totalReviews += current.totalReviews;
          existing.averageRating = (existing.averageRating * existing.totalReviews + current.averageRating * current.totalReviews) / (existing.totalReviews + current.totalReviews);
          existing.comparisonToPrevious += current.comparisonToPrevious;
          existing.sentimentBreakdown.positive += current.sentimentBreakdown.positive;
          existing.sentimentBreakdown.neutral += current.sentimentBreakdown.neutral;
          existing.sentimentBreakdown.negative += current.sentimentBreakdown.negative;
          existing.commonKeywords = [...new Set([...existing.commonKeywords, ...current.commonKeywords])];
          existing.dailyRatings = [...existing.dailyRatings, ...current.dailyRatings];
        }
      });
    });
    return Object.values(aggregatedData).sort((a, b) => b.data.totalReviews - a.data.totalReviews);
  }, []);

  useEffect(() => {
    if (selectedDateRanges.length > 0) {
      const allSelectedWeeks = selectedDateRanges.flatMap(range => range.weeks);
      const sortedData = aggregateBusinessData(allSelectedWeeks);
      setBusinessData(sortedData);
      setSelectedBusinesses(prevSelected => {
        const existingBusinesses = prevSelected.filter(name => 
          sortedData.some(business => business.name === name)
        );
        return existingBusinesses.length > 0 ? existingBusinesses : sortedData.slice(0, 3).map(business => business.name);
      });
    }
  }, [selectedDateRanges, aggregateBusinessData]);

  const selectOptions = useMemo(() => {
    const uniqueBusinesses = Array.from(new Set(businessData.map(business => business.name)));
    return uniqueBusinesses.map(name => {
      const business = businessData.find(b => b.name === name);
      return {
        value: name,
        label: `${name} (${business?.data.totalReviews || 0} reviews)`
      };
    });
  }, [businessData]);

  const handleBusinessSelection = (selectedOptions) => {
    setSelectedBusinesses(selectedOptions.map(option => option.value));
  };

  const handleDateRangeChange = (selectedOptions) => {
    setSelectedDateRanges(selectedOptions);
  };

  const handleToggleView = (view) => {
    if (view === 'years') {
      navigate('/compare-year');
    }
  };

  const selectedBusinessesData = useMemo(() => 
    businessData.filter(business => selectedBusinesses.includes(business.name)),
  [businessData, selectedBusinesses]);

  const prepareBarData = useCallback((key) => {
    return selectedBusinessesData.map(business => ({
      name: business.name,
      value: business.data[key]
    }));
  }, [selectedBusinessesData]);

  const compareDailyData = useMemo(() => {
    const allDates = new Set();
    selectedBusinessesData.forEach(business => {
      business.data.dailyRatings.forEach(day => {
        allDates.add(day.date);
      });
    });

    const sortedDates = Array.from(allDates).sort((a, b) => new Date(a.replace(/-/g, "/")) - new Date(b.replace(/-/g, "/")));

    return sortedDates.map(date => {
      const dataPoint = { date };
      selectedBusinessesData.forEach(business => {
        const dayData = business.data.dailyRatings.find(d => d.date === date);
        dataPoint[business.name] = dayData ? dayData.negative + dayData.positive + dayData.neutral : 0;
      });
      return dataPoint;
    });
  }, [selectedBusinessesData]);

  const formatXAxis = (dateString) => {
    const date = new Date(dateString.replace(/-/g, "/"));
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const getColor = (businessName) => {
    return businessColors[businessName] || colors[0];
  };

  const renderBarChart = (data, yAxisDomain = [0, 'auto']) => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} layout="vertical">
        <XAxis type="number" domain={yAxisDomain} />
        <YAxis type="category" dataKey="name" width={150} />
        <Tooltip />
        <Bar dataKey="value">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getColor(entry.name)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );

  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <VStack spacing={4}>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          <Text fontSize="xl" fontWeight="bold">Loading Comparison Data...</Text>
        </VStack>
      </Flex>
    );
  }

  return (
    <Box maxWidth="6xl" margin="auto" padding={4}>
      <Flex justifyContent="space-between" alignItems="center" marginBottom={4}>
        <Heading as="h1" size="xl">Business Comparison Dashboard</Heading>
        <Box width="300px">
          <Select
            isMulti
            options={availableWeeks}
            onChange={handleDateRangeChange}
            value={selectedDateRanges}
            placeholder="Select date range..."
            isSearchable
            chakraStyles={{
              container: (provided) => ({
                ...provided,
                width: '100%',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                background: 'transparent',
                border: 'none',
                p: 0,
              }),
            }}
          />
        </Box>
        <ButtonGroup isAttached variant="outline">
          <Button isActive colorScheme="blue" onClick={() => handleToggleView('weeks')}>Weeks</Button>
          <Button onClick={() => handleToggleView('years')}>Year</Button>
        </ButtonGroup>
      </Flex>

      <Box marginBottom={4}>
        <Select
          isMulti
          name="businesses"
          options={selectOptions}
          placeholder="Select businesses to compare..."
          closeMenuOnSelect={false}
          onChange={handleBusinessSelection}
          value={selectOptions.filter(option => selectedBusinesses.includes(option.value))}
          chakraStyles={{
            container: (provided) => ({
              ...provided,
              width: '100%',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              background: 'transparent',
              border: 'none',
              p: 0,
            }),
          }}
        />
      </Box>

      <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4} marginY={4}>
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Average Rating</Text>
          {renderBarChart(prepareBarData('averageRating'), [0, 5])}
        </Box>

        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Total Reviews</Text>
          {renderBarChart(prepareBarData('totalReviews'))}
        </Box>

        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Comparison to Previous Period</Text>
          {renderBarChart(prepareBarData('comparisonToPrevious'))}
        </Box>
      </Grid>

      <Box borderWidth={1} borderRadius="lg" padding={4} marginY={4}>
        <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Reviews per Day</Text>
        <Box height="300px">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={compareDailyData}>
              <XAxis 
                dataKey="date" 
                tickFormatter={formatXAxis}
                interval="preserveStartEnd"
                minTickGap={50}
              />
              <YAxis />
              <Tooltip labelFormatter={formatXAxis} />
              <Legend />
              {selectedBusinessesData.map((business) => (
                <Line 
                  key={business.name}
                  type="monotone"
                  dataKey={business.name}
                  stroke={getColor(business.name)}
                  strokeWidth={2}
                  dot={false}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>

      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4} marginY={4}>
        {selectedBusinessesData.map((business) => (
          <Box key={business.name} borderWidth={1} borderRadius="lg" padding={4}>
            <Heading as="h3" size="md" marginBottom={2}>{business.name}</Heading>
            <Text fontWeight="semibold" marginBottom={2}>Sentiment Breakdown</Text>
            <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Text>Positive</Text>
              <Badge colorScheme="green">{business.data.sentimentBreakdown.positive}</Badge>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Text>Neutral</Text>
              <Badge colorScheme="gray">{business.data.sentimentBreakdown.neutral}</Badge>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Text>Negative</Text>
              <Badge colorScheme="red">{business.data.sentimentBreakdown.negative}</Badge>
            </Flex>
            <Text fontWeight="semibold" marginTop={4} marginBottom={2}>Common Keywords</Text>
            <Flex flexWrap="wrap" gap={2}>
              {business.data.commonKeywords.map((keyword, index) => (
                <Badge key={index} variant="outline">{keyword}</Badge>
              ))}
            </Flex>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default BusinessComparisonDashboard;