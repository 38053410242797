import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { scaleLinear } from 'd3-scale';
import  ExportButton  from './ExportButton'; // Add this line


const colors = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
'#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
'#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
'#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
'#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
'#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
'#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
'#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
'#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
'#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];


const BarGraph = ({ data, method}) => {
    // Find the minimum and maximum values in the data
    const minValue = Math.min(...data.map(d => d[method]));
    const maxValue = Math.max(...data.map(d => d[method]));

    // Create a color scale
    const colorScale = scaleLinear()
        .domain([minValue, (minValue + maxValue) / 2, maxValue])
        .range(['dodgerblue', 'peachpuff', 'darkorange']);

    return (
        <ResponsiveBar
        data={data}
        indexBy="name"
        margin={{ top: 20, right: 20, bottom: 45, left: 50 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ id, data }) => colorScale(data[method])} // Use the color scale
        keys={[method]}
        borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 1,
            tickPadding: 5,
            tickRotation: -45,
            legendPosition: 'middle',
            legendOffset: 32,
            truncateTickAt: 5
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: method,
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0,
            format: v => v >= 1000 ? `${v/1000}k` : v, // Format the tick values only if they are in the thousands
        }}
        labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
        />
    );
};

const BarGraphContainer = ({data, method, title}) => {
    const [reviews, setReviews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    // useEffect(() => {
    //     const fetchReviews = async () => {
    //         const reviewsData = await getReviews();
    //         setReviews(reviewsData.slice(0, 10)); // only take the top 10 items
    //     };

    //     fetchReviews();
    // }, []);

    useEffect(() => {
        setReviews(data);
        setIsLoading(false);
      }, [data]);

    // console.log('we got the reviews pre graph', reviews);
    return <BarGraph data={reviews} method={method}/>;
};

export default BarGraphContainer;