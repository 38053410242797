import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FiExternalLink } from 'react-icons/fi';
import { Input } from '@chakra-ui/react';
import  ExportButton  from './ExportButton'; // Add this line



function TrafficTable({ trafficData, onRowSelect }) {
    const [searchTerm, setSearchTerm] = useState("");
    const handleChange = (event) => setSearchTerm(event.target.value)
    const sortedTrafficData = [...trafficData].sort((a, b) => b.traffic - a.traffic);


  const columns = [
    {
      name: 'name',
      selector: 'name',
      sortable: true,
      cell: row => (
        <a href={`https://www.${row.url}`} target="_blank" rel="noopener noreferrer">
          <span style={{ display: 'flex', alignItems: 'center' }}>{row.name}<FiExternalLink /></span>
        </a>
      ),
    },
    {
      name: 'traffic count',
      selector: 'traffic',
      sortable: true,
      wrap: true
    }
  ];

  const handleRowClick = (rowData) => {
    onRowSelect(rowData);
  };

return (
    <div style={{ paddingBottom: '50px' }}> {/* Add bottom padding */}
      <div style={{ paddingBottom: '8px' }}> {/* Add padding to the bottom of the Input container */}
  <Input 
    placeholder='search'
    onChange={handleChange} 
    width="33%"
    style={{ backgroundColor: 'white', height: '30px' }} // Removed paddingBottom from here
  />
</div>
        {/* <ExportButton data={trafficData} filename="traffic.csv" /> */}
      <DataTable
        columns={columns}
        data={sortedTrafficData ? sortedTrafficData.filter((item) => {
            if (searchTerm === "") {
              return item;
            } else if (
              item.name.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return item;
            }
          }) : []}
        pagination
        paginationRowsPerPageOptions={[20, 50, 100]}
        paginationPerPage={20} // Set default rows per page to 30
        responsive
        searchable
        highlightOnHover
        striped
        dense
        selectableRows
        selectableRowsHighlight
        onSelectedRowsChange={handleRowClick}
      />
    </div>
  );
}

export default TrafficTable;