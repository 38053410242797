import React, { useEffect, useState } from 'react';
import { Grid, Box, Heading, Button, Flex } from "@chakra-ui/react";
import ReviewTable from './ReviewTable'; 
import PieGraphContainer from './PieGraph';
import BarGraphContainer from './BarGraph';
import LineGraphContainer from './LineGraph';
import { getReviews } from '../services/reviewService';  // adjust the path as needed
import { Spinner } from "@chakra-ui/react"; // Import Spinner from Chakra UI
import RankingListComponent from './RankingListComponent';



function RankParentComponent () { 
    const [reviews, setReviews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedData, setSelectedData] = useState([]);
    const [timeSeriesData, setTimeSeriesData] = useState([]);
    const [sortOrders, setSortOrders] = useState({
      rating_count: 'asc',
      rating_avg: 'asc',
      difference: 'asc',
      monthly_share: 'asc',
      net_growth: 'asc'
    });
    const [top5MonthlyGain, setTop5MonthlyGain] = useState([]);
    const [top5MonthlyShare, setTop5MonthlyShare] = useState([]);
    const [top5NetGrowth, setTop5NetGrowth] = useState([]);
    const [top5RatingCount, setTop5RatingCount] = useState([]);
    const [top5Difference, setTop5Difference] = useState([]);
    const [top5RatingAvg, setTop5RatingAvg] = useState([]);

    const [bottom5MonthlyGain, setBottom5MonthlyGain] = useState([]);
    const [bottom5MonthlyShare, setBottom5MonthlyShare] = useState([]);
    const [bottom5NetGrowth, setBottom5NetGrowth] = useState([]);
    const [bottom5RatingCount, setBottom5RatingCount] = useState([]);
    const [bottom5Difference, setBottom5Difference] = useState([]);
    const [bottom5RatingAvg, setBottom5RatingAvg] = useState([]);
    
    useEffect(() => {
        getReviews().then(data => {
          // am i doing this at the right time? -- feels a little slow
          setTimeSeriesData(data);
          // calculate the per month change and the month to month difference
          const sortedData = data.sort((a, b) => b.rating_count - a.rating_count);
          const topTwoPerName = sortedData.reduce((acc, review) => {
            if (!acc[review.name]) {
              acc[review.name] = [review];
            } else {
              const maxReview = acc[review.name][0];
              const secondMaxReview = acc[review.name][1];
              if (!secondMaxReview || review.rating_count > secondMaxReview.rating_count) {
                if (review.rating_count < maxReview.rating_count) {
                  acc[review.name][1] = review;
                } else if (review.rating_count > maxReview.rating_count) {
                  acc[review.name][1] = maxReview;
                  acc[review.name][0] = review;
                }
              }
            }
            return acc;
          }, {});
          
          const reviewsWithDifference = Object.values(topTwoPerName).map(([first, second]) => {
            if (first && second) {
              const date1 = new Date(first.timestamp);
              const date2 = new Date(second.timestamp);
              const diffDays = Math.abs(date2 - date1) / (1000 * 60 * 60 * 24);
              const diffMonths = diffDays / 30.44; // calculate the difference in months
              const difference = first.rating_count - second.rating_count; // calculate the difference
              first.difference = difference; // set difference per datapoint
              first.monthly_gain = difference / diffMonths; // set monthly_gain per datapoint
              first.net_growth = (difference / first.rating_count) * 100; // calculate and set net_growth per datapoint
            } else {
              first.difference = 0;
              first.monthly_gain = 0;
              first.net_growth = 0;
            }
            return first;
          });

          // Calculate the sum of monthly_gain for all businesses
          const totalMonthlyGain = reviewsWithDifference.reduce((sum, review) => sum + review.monthly_gain, 0);

          // Calculate the monthly_share for each business
          reviewsWithDifference.forEach(review => {
            review.monthly_share = (review.monthly_gain / totalMonthlyGain) * 100;
          });

          setReviews(reviewsWithDifference);
          setSelectedData(reviewsWithDifference);
          setIsLoading(false);
        //   console.log(reviewsWithDifference);

          const top5MonthlyGain = [...reviewsWithDifference].sort((a, b) => b.monthly_gain - a.monthly_gain).slice(0, 5);
          const top5MonthlyShare = [...reviewsWithDifference].sort((a, b) => b.monthly_share - a.monthly_share).slice(0, 5);
          const top5NetGrowth = [...reviewsWithDifference].sort((a, b) => b.net_growth - a.net_growth).slice(0, 5);
          const top5RatingCount = [...reviewsWithDifference].sort((a, b) => b.rating_count - a.rating_count).slice(0, 5);
          const top5Difference = [...reviewsWithDifference].sort((a, b) => b.difference - a.difference).slice(0, 5);
          const top5RatingAvg = [...reviewsWithDifference].sort((a, b) => b.rating_avg - a.rating_avg).slice(0, 5);

          // set these arrays in the state if you want to use them in the render method
          setTop5MonthlyGain(top5MonthlyGain);
          setTop5MonthlyShare(top5MonthlyShare);
          setTop5NetGrowth(top5NetGrowth);
          setTop5RatingCount(top5RatingCount);
          setTop5Difference(top5Difference);
          setTop5RatingAvg(top5RatingAvg);

            const bottom5MonthlyGain = [...reviewsWithDifference].sort((a, b) => a.monthly_gain - b.monthly_gain).slice(0, 5);
            const bottom5MonthlyShare = [...reviewsWithDifference].sort((a, b) => a.monthly_share - b.monthly_share).slice(0, 5);
            const bottom5NetGrowth = [...reviewsWithDifference].sort((a, b) => a.net_growth - b.net_growth).slice(0, 5);
            const bottom5RatingCount = [...reviewsWithDifference].sort((a, b) => a.rating_count - b.rating_count).slice(0, 5);
            const bottom5Difference = [...reviewsWithDifference].sort((a, b) => a.difference - b.difference).slice(0, 5);
            const bottom5RatingAvg = [...reviewsWithDifference].sort((a, b) => a.rating_avg - b.rating_avg).slice(0, 5);

            // set these arrays in the state if you want to use them in the render method
            setBottom5MonthlyGain(bottom5MonthlyGain);
            setBottom5MonthlyShare(bottom5MonthlyShare);
            setBottom5NetGrowth(bottom5NetGrowth);
            setBottom5RatingCount(bottom5RatingCount);
            setBottom5Difference(bottom5Difference);
            setBottom5RatingAvg(bottom5RatingAvg);
        });
      }, []);

      if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Spinner size="xl" />
            </Box>
        ); // Render Spinner if isLoading is true
    }
    return (
<Grid templateColumns="repeat(2, 1fr)" gap={6} pl={4}>
    <RankingListComponent side="top" title="👍 Popular" data={top5RatingCount} valueKey="rating_count" unit="ratings" />
    <RankingListComponent side="bottom" title="👎 Unpopular" data={bottom5RatingCount} valueKey="rating_count" unit="ratings" />
    <RankingListComponent side="top" title="📈 Risers" data={top5MonthlyGain} valueKey="monthly_gain" unit="ratings/capture" />
    <RankingListComponent side="bottom" title="📉 Fallers" data={bottom5MonthlyGain} valueKey="monthly_gain" unit="ratings/capture" />
    <RankingListComponent side="top" title="🔥 Hottest" data={top5Difference} valueKey="difference" unit="ratings" />
    <RankingListComponent side="bottom" title="❄️ Coldest" data={bottom5Difference} valueKey="difference" unit="ratings" />
    <RankingListComponent side="top" title="🌱 Growth" data={top5NetGrowth} valueKey="net_growth" unit="%" />
    <RankingListComponent side="bottom" title="🍂 Decline" data={bottom5NetGrowth} valueKey="net_growth" unit="%" />
  {/* <RankingListComponent side="top" title="🏆 Best" data={top5RatingAvg} valueKey="rating_avg" unit="ratings" /> */}
  {/* <RankingListComponent side="bottom" title="🚽 Worst" data={bottom5RatingAvg} valueKey="rating_avg" unit="ratings" /> */}
</Grid>
    );

}

export default RankParentComponent;