import React, { useEffect, useState } from 'react';
import { getTraffic } from '../services/trafficService';
import TrafficTable from './TrafficTable';
import { Flex, Box, Text, Heading} from "@chakra-ui/react";
import BarGraphContainer from './BarGraph';

function TrafficParentComponent() {
  const [trafficData, setTrafficData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedData, setSelectedData] = useState([]);


  useEffect(() => {
    getTraffic()
      .then(data => {
        // console.log('Traffic data:', data);
        // Sort the data from lowest to highest
        const sortedData = data.sort((a, b) => a.traffic - b.traffic);
        setTrafficData(sortedData);
        setSelectedData(sortedData); // Set selectedData to all data initially
        setIsLoading(false); // Set loading to false after the data has been fetched
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false); // Set loading to false even if there's an error
      });
  }, []);
  
  const handleRowSelection = (selectedRowData) => { 
    setSelectedData(
        selectedRowData.selectedRows.length > 0 
          ? selectedRowData.selectedRows.sort((a, b) => a.traffic - b.traffic) 
          : trafficData
      );
  } 

  if (isLoading) { // Render a loading message while the data is being fetched
    return <div>Loading...</div>;
  }

  return (
    <Flex direction="column" h="100vh" align-items='center'>
      <Heading as="h2" size="md" textAlign="center">Explore Web Traffic</Heading>
      <Flex direction="row" flex="1">
        <Box flex="0.2" pl={4} pt={4}>
          <TrafficTable trafficData={trafficData} searchTerm={searchTerm} onRowSelect={handleRowSelection} />
        </Box>
        <Box flex="0.8" h="80vh">
          <BarGraphContainer title="Traffic" data={selectedData} method="traffic" />
        </Box>
      </Flex>
    </Flex>
  );
}

export default TrafficParentComponent;