// Navbar.js
import React from 'react';
import { Tooltip, Box, Text, Spacer, Link, Menu, MenuButton, MenuList, MenuItem, IconButton, Flex, Image, Icon} from '@chakra-ui/react';
import { FaHistory, FaRegCalendarAlt, FaChartBar, FaTools, FaBalanceScale } from 'react-icons/fa';

function Navbar() {
  return (
    <Flex bg="white.500" p={4} color="white">
      <Link href="/" style={{ textDecoration: 'none' }}>
        <Image src="/output.png" alt="Logo" boxSize="33px" />
      </Link>
      <Box display="flex" alignItems="center">
        <Text fontSize="xl" color='black'>Localytics</Text>
        <Text fontSize="sm" color='black' ml={2}>Beta</Text>
      </Box>
      <Spacer />
      <Box>
        <Flex alignItems="center" justifyContent="space-between" width="auto">
          <Link href="/week-in-review" color="black" pr={5}>
            <IconButton
              as="a"
              icon={<FaChartBar />}
              bg="white"
              color="black"
              _hover={{ bg: 'gray.100' }}
            />
          </Link>
          <Link href="/compare-week" color="black" pr={5}>
            <IconButton
              as="a"
              icon={<FaBalanceScale />}
              bg="white"
              color="black"
              _hover={{ bg: 'gray.100' }}
            />
          </Link>
          <Menu>
            <Box pr={4} bg="white">
              <Tooltip label="Under Construction" placement="top">
                <MenuButton as={IconButton} bg="white" icon={<FaTools />} color="black" />
              </Tooltip>
            </Box>
            <MenuList>
              <MenuItem as={Link} href="/explore" color="black">
                Explore
              </MenuItem>
              <MenuItem as={Link} href="/rank" color="black">
                Rank
              </MenuItem>
              <MenuItem as={Link} href="/track" color="black">
                Track
              </MenuItem>
              <MenuItem as={Link} href="/traffic" color="black">
                Traffic
              </MenuItem>
            </MenuList>
          </Menu>
          <Link href="/changelog" color="black" >
            <IconButton
              as="a"
              icon={<FaHistory />}
              bg="white"
              color="black"
              _hover={{ bg: 'gray.100' }}
            />
          </Link>
        </Flex>
      </Box>
    </Flex>
  );
}

export default Navbar;