export const combineWeekData = (weeks) => {
  if (weeks.length === 0) return null;

  const combinedData = {
    collection: weeks.map(w => w.collection).join(', '),
    sunday: `${weeks[weeks.length - 1].sunday} to ${weeks[0].sunday}`,
    data: weeks[0].data.map(business => {
      const combinedBusiness = { ...business };
      combinedBusiness.data = weeks.reduce((acc, week) => {
        const matchingBusiness = week.data.find(b => b.name === business.name);
        if (matchingBusiness && matchingBusiness.data) {
          Object.keys(matchingBusiness.data).forEach(key => {
            if (typeof matchingBusiness.data[key] === 'number') {
              if (key === 'averageRating') {
                // For averageRating, we'll calculate the weighted average
                const totalReviews = acc.totalReviews || 0;
                const newTotalReviews = totalReviews + matchingBusiness.data.totalReviews;
                acc[key] = ((acc[key] || 0) * totalReviews + matchingBusiness.data[key] * matchingBusiness.data.totalReviews) / newTotalReviews;
              } else {
                acc[key] = (acc[key] || 0) + matchingBusiness.data[key];
              }
            } else if (typeof matchingBusiness.data[key] === 'object' && key !== 'dailyRatings' && key !== 'dailyReviewCounts') {
              acc[key] = acc[key] || {};
              Object.keys(matchingBusiness.data[key] || {}).forEach(subKey => {
                acc[key][subKey] = (acc[key][subKey] || 0) + (matchingBusiness.data[key][subKey] || 0);
              });
            }
          });
          
          // Concatenate dailyRatings and dailyReviewCounts
          ['dailyRatings', 'dailyReviewCounts'].forEach(arrayKey => {
            if (Array.isArray(matchingBusiness.data[arrayKey])) {
              acc[arrayKey] = (acc[arrayKey] || []).concat(matchingBusiness.data[arrayKey]);
            }
          });

          // Combine commonKeywords
          if (Array.isArray(matchingBusiness.data.commonKeywords)) {
            if (!Array.isArray(acc.commonKeywords)) {
              acc.commonKeywords = [];
            }
            matchingBusiness.data.commonKeywords.forEach(keyword => {
              if (!acc.commonKeywords.includes(keyword)) {
                acc.commonKeywords.push(keyword);
              }
            });
          }
        }
        return acc;
      }, {});

      // Ensure commonKeywords is always an array
      if (!Array.isArray(combinedBusiness.data.commonKeywords)) {
        combinedBusiness.data.commonKeywords = [];
      }

      // Sort and deduplicate dailyRatings and dailyReviewCounts
      ['dailyRatings', 'dailyReviewCounts'].forEach(arrayKey => {
        if (Array.isArray(combinedBusiness.data[arrayKey])) {
          combinedBusiness.data[arrayKey] = combinedBusiness.data[arrayKey]
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .filter((item, index, self) =>
              index === self.findIndex((t) => t.date === item.date)
            );
        }
      });

      // Recalculate comparisonToPrevious
      const totalReviews = combinedBusiness.data.totalReviews || 0;
      const previousWeekReviews = combinedBusiness.data.previousWeekReviews || 0;
      combinedBusiness.data.comparisonToPrevious = previousWeekReviews > 0
        ? (totalReviews - previousWeekReviews) / previousWeekReviews
        : totalReviews > 0 ? 1 : 0;

      return combinedBusiness;
    })
  };
  return combinedData;
};

export const processWeekData = (selectedWeeks) => {
  console.log('Selected weeks:', selectedWeeks);
  const combinedData = combineWeekData(selectedWeeks);
  if (combinedData && combinedData.data) {
    return combinedData.data.sort((a, b) => (b.data?.totalReviews || 0) - (a.data?.totalReviews || 0));
  }
  return [];
};