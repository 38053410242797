import React, { useEffect, useState, useMemo } from 'react';
import { Grid, Box, Heading, Button, Flex, Text } from "@chakra-ui/react";
import ReviewTable from './ReviewTable'; 
import PieGraphContainer from './PieGraph';
import BarGraphContainer from './BarGraph';
import LineGraphContainer from './LineGraph';
import { getReviews } from '../services/reviewService';  // adjust the path as needed
import { Spinner } from "@chakra-ui/react"; // Import Spinner from Chakra UI




function ExploreParentComponent () {
    const [reviews, setReviews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedData, setSelectedData] = useState([]);
    const [timeSeriesData, setTimeSeriesData] = useState([]);
    const [sortOrders, setSortOrders] = useState({
      rating_count: 'asc',
      rating_avg: 'asc',
      difference: 'asc',
      weekly_share: 'asc',
      net_growth: 'asc'
    });


    const sortData = (data, method, order) => {
      if (order === 'random') {
        return [...data].sort(() => Math.random() - 0.5);
      }
      return [...data].sort((a, b) => order === 'asc' ? a[method] - b[method] : b[method] - a[method]);
    }

    const handleSort = (method) => {
      const orders = ['asc', 'desc', 'random'];
      const currentOrderIndex = orders.indexOf(sortOrders[method]);
      const nextOrderIndex = (currentOrderIndex + 1) % orders.length;
      setSortOrders(prevState => ({
        ...prevState,
        [method]: orders[nextOrderIndex]
      }));
    }
    
    useEffect(() => {
        getReviews().then(data => {
          // am i doing this at the right time? -- feels a little slow
          setTimeSeriesData(data);
          // calculate the per month change and the month to month difference
          const sortedData = data.sort((a, b) => b.rating_count - a.rating_count);
          const topTwoPerName = sortedData.reduce((acc, review) => {
            if (!acc[review.name]) {
              acc[review.name] = [review];
            } else {
              const maxReview = acc[review.name][0];
              const secondMaxReview = acc[review.name][1];
              if (!secondMaxReview || review.rating_count > secondMaxReview.rating_count) {
                if (review.rating_count < maxReview.rating_count) {
                  acc[review.name][1] = review;
                } else if (review.rating_count > maxReview.rating_count) {
                  acc[review.name][1] = maxReview;
                  acc[review.name][0] = review;
                }
              }
            }
            return acc;
          }, {});
          
          const reviewsWithDifference = Object.values(topTwoPerName).map(([first, second]) => {
            if (first && second) {
              const date1 = new Date(first.timestamp);
              const date2 = new Date(second.timestamp);
              const diffDays = Math.abs(date2 - date1) / (1000 * 60 * 60 * 24);
              const diffMonths = diffDays / 30.44; // calculate the difference in months
              const difference = first.rating_count - second.rating_count; // calculate the difference
              first.difference = difference; // set difference per datapoint
              first.monthly_gain = difference / diffMonths; // set monthly_gain per datapoint
              first.net_growth = (difference / first.rating_count) * 100; // calculate and set net_growth per datapoint
            } else {
              first.difference = 0;
              first.monthly_gain = 0;
              first.net_growth = 0;
            }
            return first;
          });

          // Calculate the sum of monthly_gain for all businesses
          const totalMonthlyGain = reviewsWithDifference.reduce((sum, review) => sum + review.monthly_gain, 0);

          // Calculate the weekly_share for each business
          reviewsWithDifference.forEach(review => {
            review.weekly_share = (review.monthly_gain / totalMonthlyGain) * 100;
          });

          setReviews(reviewsWithDifference);
          setSelectedData(reviewsWithDifference);
          setIsLoading(false);
          // console.log(reviewsWithDifference);
        });
      }, []);

      const handleRowSelection = (selectedRowData) => {
        setSelectedData(selectedRowData.selectedRows.length > 0 ? selectedRowData.selectedRows : reviews);
      } 

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Spinner size="xl" />
            </Box>
        ); // Render Spinner if isLoading is true
    }

    return (
      <Flex direction="row">
          <Box flex="0.4" pl={4} pt={4}>
              <ReviewTable data={reviews} onRowSelect={handleRowSelection} />
          </Box>
          <Box flex="0.6">
              <Box flexDirection="column" height="500px">
                  <GraphContainer title="Rating Count" sortKey="rating_count" />
                  <GraphContainer title="Rating" sortKey="rating_avg" />
                  <GraphContainer title="Past Week Gain" sortKey="difference" />
                  <GraphContainer title="Weekly Gain Share" sortKey="weekly_share" />
                  <GraphContainer title="Relative Growth" sortKey="net_growth" />
              </Box>
          </Box>
      </Flex>
  );
    
  function GraphContainer({ title, sortKey }) {
    const sortedData = useMemo(() => sortData(selectedData, sortKey, sortOrders[sortKey]), [selectedData, sortKey, sortOrders]);
    return (
      <Flex direction="row" justifyContent="center" height="200px" paddingTop="20px" paddingLeft="20px">
        {/* <Button size="sm" onClick={() => handleSort(sortKey)}>Sort</Button> */}
        <div style={{ width: '100px', height: '50px' }}>
  <Text>{title}</Text>
</div>
        <Box flex="1">
          <BarGraphContainer data={sortedData} method={sortKey}/>
        </Box>
        <Box flex="1">
          <PieGraphContainer data={sortedData} method={sortKey}/>
        </Box>
      </Flex>
    );
  }
}

export default ExploreParentComponent;