import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Flex, Grid, Heading, Text, Badge, Icon, Spinner, VStack, ButtonGroup, Button } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, LineChart, Line, Cell } from 'recharts';
import { StarIcon } from '@chakra-ui/icons';
import getYearData from '../services/yearService.js';
import { useNavigate } from 'react-router-dom';

const BusinessComparisonYearDashboard = () => {
  const [allYearData, setAllYearData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [businessColors, setBusinessColors] = useState({});
  const navigate = useNavigate();

  const colors = ['#4299E1', '#48BB78', '#ED8936', '#9F7AEA', '#F56565', '#4FD1C5', '#ED64A6'];

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getYearData();
      setAllYearData(data);
      
      const sortedData = aggregateBusinessData(data);
      setBusinessData(sortedData);

      const allBusinesses = new Set(sortedData.map(business => business.name));
      const newBusinessColors = {};
      Array.from(allBusinesses).forEach((business, index) => {
        newBusinessColors[business] = colors[index % colors.length];
      });
      setBusinessColors(newBusinessColors);

      setSelectedBusinesses(sortedData.slice(0, 3).map(business => business.name));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const aggregateBusinessData = useCallback((yearData) => {
    return yearData.sort((a, b) => b.data.totalReviews - a.data.totalReviews);
  }, []);

  const selectOptions = useMemo(() => {
    return businessData.map(business => ({
      value: business.name,
      label: `${business.name} (${business.data.totalReviews || 0} reviews)`
    }));
  }, [businessData]);

  const handleBusinessSelection = (selectedOptions) => {
    setSelectedBusinesses(selectedOptions.map(option => option.value));
  };

  const handleToggleView = (view) => {
    if (view === 'weeks') {
      navigate('/compare-week');
    }
  };

  const selectedBusinessesData = useMemo(() => 
    businessData.filter(business => selectedBusinesses.includes(business.name)),
  [businessData, selectedBusinesses]);

  const prepareBarData = useCallback((key) => {
    return selectedBusinessesData.map(business => ({
      name: business.name,
      value: business.data[key]
    }));
  }, [selectedBusinessesData]);

  const compareMonthlyData = useMemo(() => {
    const allMonths = new Set();
    selectedBusinessesData.forEach(business => {
      Object.keys(business.data.monthlyReviewCounts).forEach(month => allMonths.add(month));
    });

    return Array.from(allMonths).sort().map(month => {
      const dataPoint = { month };
      selectedBusinessesData.forEach(business => {
        dataPoint[business.name] = business.data.monthlyReviewCounts[month] || 0;
      });
      return dataPoint;
    });
  }, [selectedBusinessesData]);

  const formatXAxis = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short' });
  };

  const getColor = (businessName) => {
    return businessColors[businessName] || colors[0];
  };

  const renderBarChart = (data, yAxisDomain = [0, 'auto']) => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} layout="vertical">
        <XAxis type="number" domain={yAxisDomain} />
        <YAxis type="category" dataKey="name" width={150} />
        <Tooltip />
        <Bar dataKey="value">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getColor(entry.name)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );

  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <VStack spacing={4}>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          <Text fontSize="xl" fontWeight="bold">Loading Yearly Comparison Data...</Text>
        </VStack>
      </Flex>
    );
  }

  return (
    <Box maxWidth="6xl" margin="auto" padding={4}>
      <Flex justifyContent="space-between" alignItems="center" marginBottom={4}>
        <Heading as="h1" size="xl">Business Comparison Dashboard</Heading>
        <ButtonGroup isAttached variant="outline">
          <Button onClick={() => handleToggleView('weeks')}>Weeks</Button>
          <Button isActive colorScheme="blue">Year</Button>
        </ButtonGroup>
      </Flex>

      <Box marginBottom={4}>
        <Select
          isMulti
          name="businesses"
          options={selectOptions}
          placeholder="Select businesses to compare..."
          closeMenuOnSelect={false}
          onChange={handleBusinessSelection}
          value={selectOptions.filter(option => selectedBusinesses.includes(option.value))}
          chakraStyles={{
            container: (provided) => ({
              ...provided,
              width: '100%',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              background: 'transparent',
              border: 'none',
              p: 0,
            }),
          }}
        />
      </Box>

      <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4} marginY={4}>
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Average Rating</Text>
          {renderBarChart(prepareBarData('averageRating'), [0, 5])}
        </Box>

        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Total Reviews</Text>
          {renderBarChart(prepareBarData('totalReviews'))}
        </Box>

        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Monthly Average</Text>
          {renderBarChart(selectedBusinessesData.map(business => ({
            name: business.name,
            value: Math.round(business.data.totalReviews / Object.keys(business.data.monthlyReviewCounts).length)
          })))}
        </Box>
      </Grid>

      <Box borderWidth={1} borderRadius="lg" padding={4} marginY={4}>
        <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Reviews per Month</Text>
        <Box height="300px">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={compareMonthlyData}>
              <XAxis dataKey="month" tickFormatter={formatXAxis} />
              <YAxis />
              <Tooltip labelFormatter={formatXAxis} />
              <Legend />
              {selectedBusinessesData.map((business) => (
                <Line 
                  key={business.name}
                  type="monotone"
                  dataKey={business.name}
                  stroke={getColor(business.name)}
                  strokeWidth={2}
                  dot={false}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>

      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4} marginY={4}>
        {selectedBusinessesData.map((business) => (
          <Box key={business.name} borderWidth={1} borderRadius="lg" padding={4}>
            <Heading as="h3" size="md" marginBottom={2}>{business.name}</Heading>
            <Text fontWeight="semibold" marginBottom={2}>Sentiment Breakdown</Text>
            <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Text>Positive</Text>
              <Badge colorScheme="green">{business.data.sentimentBreakdown.positive}</Badge>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Text>Neutral</Text>
              <Badge colorScheme="gray">{business.data.sentimentBreakdown.neutral}</Badge>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Text>Negative</Text>
              <Badge colorScheme="red">{business.data.sentimentBreakdown.negative}</Badge>
            </Flex>
            <Text fontWeight="semibold" marginTop={4} marginBottom={2}>Common Keywords</Text>
            <Flex flexWrap="wrap" gap={2}>
              {business.data.commonKeywords.map((keyword, index) => (
                <Badge key={index} variant="outline">{keyword}</Badge>
              ))}
            </Flex>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default BusinessComparisonYearDashboard;