import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { getReviews } from '../services/reviewService';  // adjust the path as needed
import { BeatLoader } from 'react-spinners';
import { Input } from '@chakra-ui/react';
import { FiExternalLink } from 'react-icons/fi';
import  ExportButton  from './ExportButton'; // Add this line


function ReviewTable({ data, onRowSelect }) {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => setSearchTerm(event.target.value)
  
  useEffect(() => {
    setReviews(data);
    setIsLoading(false);
  }, [data]);

  const handleRowClick = (rowData) => {
    onRowSelect(rowData);
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f5f5f5' }}>
        <BeatLoader color="#4A90E2" />
      </div>
    );
  }

  const columns = [
    {
      name: 'name',
      selector: 'name',
      sortable: true,
      cell: row => (
          <a href={row.maps_uri} target="_blank" rel="noopener noreferrer">
              <span style={{ display: 'flex', alignItems: 'center' }}>{row.name}<FiExternalLink /></span>
          </a>
      ),
    },
    {
      name: 'rating count',
      selector: 'rating_count',
      sortable: true,
      wrap: true
    },
    {
      name: 'past week',
      selector: 'difference',
      sortable: true,
      cell: row => row.difference === 0 
      ? <>no change since last capture <strong>{new Date(row.timestamp).toLocaleDateString()} {new Date(row.timestamp).toLocaleTimeString()}</strong> </> 
      : parseInt(row.difference)
  },
    {
        name: 'rating',
        selector: 'rating_avg',
        sortable: true,
        wrap: true
    }
    // Add more columns as needed
  ];

  return (
    <div>
      <div style={{ paddingBottom: '8px' }}> {/* Add padding to the bottom of the Input container */}
  <Input 
    placeholder='search'
    onChange={handleChange} 
    width="33%"
    style={{ backgroundColor: 'white', height: '30px' }} // Removed paddingBottom from here
  />
</div>
    {/* <ExportButton data={reviews} filename="reviews.csv" /> */}
    <DataTable
      columns={columns}
      data={reviews.filter((item) => {
        if (searchTerm === "") {
          return item;
        } else if (
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return item;
        }
      })}
      pagination
      paginationRowsPerPageOptions={[30, 50, 100]}
      paginationPerPage={30} // Set default rows per page to 30
      responsive
      searchable
      highlightOnHover
      striped
      dense
      selectableRows
      selectableRowsHighlight
      onSelectedRowsChange={handleRowClick}
  />
    </div>
  );
}

export default ReviewTable;