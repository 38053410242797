import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { scaleLinear } from 'd3-scale';

const PieGraph = ({ data }) => {
    // Find the minimum and maximum values in the data
    const minValue = Math.min(...data.map(d => d.value));
    const maxValue = Math.max(...data.map(d => d.value));

    // Create a color scale
    const colorScale = scaleLinear()
        .domain([minValue, (minValue + maxValue) / 2, maxValue])
        .range(['dodgerblue', 'peachpuff', 'darkorange']); // Changed 'lightblue' to 'peachpuff'

    return (
        <ResponsivePie
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 40 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={d => colorScale(d.value)}
        />
    );
};
// const PieGraphContainer = () => {
//     const [reviews, setReviews] = useState([]);

//     useEffect(() => {
//         const fetchReviews = async () => {
//             const reviewsData = await getReviews();
//             setReviews(reviewsData.slice(0, 10).map(item => ({ id: item.name, value: item.rating_count }))); // only take the top 10 items
//         };

//         fetchReviews();
//     }, []);
//     console.log('we got the reviews pre graph', reviews);
//     return <PieGraph data={reviews} />;
// };

function PieGraphContainer({ data, method='rating_count' }) {
    const [reviews, setReviews] = useState([]);
    // console.log('received raw', data);
    useEffect(() => {
        setReviews(data.slice(0, 5).map(item => ({ 
            id: item.name, 
            value: Math.round(item[method] * 1000) / 1000
        }))); // only take the top 10 items
        // console.log('we got the reviews pre graph', reviews);
    }, [data]);

    return <PieGraph data={reviews} />;
};

export default PieGraphContainer;