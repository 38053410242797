import React from 'react';
import { Box, Text, Badge, VStack, Grid, Flex } from '@chakra-ui/react';

const KeyPhrases = ({ data }) => {
  const processKeyPhrases = () => {
    if (!data || !data.keyPhrases || typeof data.keyPhrases !== 'object') return [];

    return Object.values(data.keyPhrases).map(item => {
      const [phrase, sentiment] = item.split(',');
      return [phrase.startsWith('0') ? phrase.slice(1) : phrase, sentiment];
    });
  };

  const renderPhrases = (sentiment) => {
    const phrases = processKeyPhrases()
      .filter(item => item[1] === sentiment)
      .slice(0, 10); // Showing top 10 phrases for each sentiment

    if (phrases.length === 0) {
      return <Text>No {sentiment} key phrases found</Text>;
    }

    return (
      <VStack align="stretch" spacing={2}>
        {phrases.map((item, index) => (
          <Flex key={index} alignItems="center">
            <Badge colorScheme={sentiment === 'positive' ? "green" : "red"} fontSize="sm" padding={1} mr={2}>
              {index + 1}
            </Badge>
            <Text fontSize="sm">{item[0]}</Text>
          </Flex>
        ))}
      </VStack>
    );
  };

  return (
    <Box borderWidth={1} borderRadius="lg" padding={4} marginBottom={4} boxShadow="md">
      <Text fontWeight="bold" fontSize="xl" marginBottom={4}>Top Key Phrases (Beta)</Text>
      {data && data.keyPhrases ? (
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <Box>
            <Text fontWeight="semibold" fontSize="lg" marginBottom={3} color="green.500">Positive Phrases</Text>
            {renderPhrases('positive')}
          </Box>
          <Box>
            <Text fontWeight="semibold" fontSize="lg" marginBottom={3} color="red.500">Negative Phrases</Text>
            {renderPhrases('negative')}
          </Box>
        </Grid>
      ) : (
        <Text>Key phrases data is missing or invalid</Text>
      )}
    </Box>
  );
};

export default KeyPhrases;