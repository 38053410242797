import axios from 'axios';



const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://sub40-platform-server-f3a1261be94d.herokuapp.com/';
axios.defaults.baseURL = baseURL;


export function getTraffic() {
//   console.log('getReviews called');
  return axios.get('/traffic')
    .then(response => {
      // console.log('getReviews response', response.data);
      return response.data;
    })
    .catch(error => {
      // console.error("Error fetching data: ", error);
      return null;
    });
}