import React, { useState, useEffect, useCallback } from 'react';
import { Box, Flex, Grid, Heading, Text, Badge, Icon, Spinner, VStack, ButtonGroup, Button } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, LineChart, Line } from 'recharts';
import { StarIcon } from '@chakra-ui/icons';
import { TrendingUpIcon, TrendingDownIcon } from './icons';
import { processWeekData } from './dataUtils';
import getWeekData from '../services/weekService.js';
import { useNavigate } from 'react-router-dom';
import KeyPhrasesSection from './KeyPhrases';

const WeeklyReviewDashboard = () => {
  const [allWeekData, setAllWeekData] = useState([]);
  const [weekData, setWeekData] = useState([]);
  const [selectedDataPoint, setSelectedDataPoint] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [availableWeeks, setAvailableWeeks] = useState([]);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getWeekData();
      setAllWeekData(data);
      
      const sortedWeeks = data.sort((a, b) => new Date(b.sunday.replace(/-/g, "/")) - new Date(a.sunday.replace(/-/g, "/")));
      
      const dateRangeOptions = sortedWeeks.map((week, index) => {
        const endDate = new Date(week.sunday.replace(/-/g, "/"));
        const startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 6);
        return {
          value: week.sunday,
          label: `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`,
          week: week
        };
      });

      setAvailableWeeks(dateRangeOptions);
      
      const defaultSelection = [dateRangeOptions[0]];
      setSelectedDateRange(defaultSelection);
      const processedData = processWeekData([defaultSelection[0].week]);
      
      setWeekData(processedData);
      
      const defaultBusiness = processedData.find(business => business.name === "Friends in Low Places Bar & Honky-Tonk");
      setSelectedDataPoint(defaultBusiness || processedData[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (selectedDateRange.length > 0 && allWeekData.length > 0) {
      const selectedWeeks = selectedDateRange.map(option => option.week);
      const processedData = processWeekData(selectedWeeks);
      setWeekData(processedData);
      
      const currentBusinessName = selectedDataPoint ? selectedDataPoint.name : "Friends in Low Places Bar & Honky-Tonk";
      const updatedBusiness = processedData.find(business => business.name === currentBusinessName);
      setSelectedDataPoint(updatedBusiness || processedData[0]);
    }
  }, [selectedDateRange, allWeekData]);

  const handleDateRangeChange = (selectedOptions) => {
    setSelectedDateRange(selectedOptions);
  };

  const handleSelectChange = (selectedOption) => {
    const selected = weekData.find(datapoint => datapoint.name === selectedOption.value);
    setSelectedDataPoint(selected);
  };

  const handleToggleView = (view) => {
    if (view === 'year') {
      navigate('/year-in-review');
    }
  };

  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <VStack spacing={4}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
          <Text fontSize="xl" fontWeight="bold">Loading Dashboard...</Text>
        </VStack>
      </Flex>
    );
  }

  if (!selectedDataPoint || !selectedDataPoint.data) {
    return <Box>No data available</Box>;
  }

  const { data } = selectedDataPoint;

  const selectOptions = weekData
    .sort((a, b) => (b.data?.totalReviews || 0) - (a.data?.totalReviews || 0))
    .map(datapoint => ({
      value: datapoint.name,
      label: `${datapoint.name} (${datapoint.data?.totalReviews || 0} reviews)`,
    }));

  const sentimentBreakdown = data.sentimentBreakdown || { positive: 0, neutral: 0, negative: 0 };

  const useDate = selectedDateRange.length > 1;

  const formatXAxis = (value) => {
    if (useDate) {
      const date = new Date(value.replace(/-/g, '/'));
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    }
    return value;
  };

  const chartData = data.dailyRatings.reduce((acc, item) => {
    const key = useDate ? item.date : new Date(item.date.replace(/-/g, '/')).toLocaleDateString('en-US', { weekday: 'short' });
    const existingEntry = acc.find(d => d[useDate ? 'date' : 'day'] === key);
    if (existingEntry) {
      existingEntry.count += item.positive + item.neutral + item.negative;
    } else {
      acc.push({ 
        [useDate ? 'date' : 'day']: key, 
        count: item.positive + item.neutral + item.negative 
      });
    }
    return acc;
  }, []);

  const barChartData = useDate
    ? data.dailyRatings
    : data.dailyRatings.reduce((acc, item) => {
        const dayOfWeek = new Date(item.date.replace(/-/g, "/")).toLocaleDateString('en-US', { weekday: 'short' });
        const existingDay = acc.find(d => d.day === dayOfWeek);
        if (existingDay) {
          existingDay.positive += item.positive;
          existingDay.neutral += item.neutral;
          existingDay.negative += item.negative;
        } else {
          acc.push({ 
            day: dayOfWeek, 
            positive: item.positive, 
            neutral: item.neutral, 
            negative: item.negative 
          });
        }
        return acc;
      }, []);

  return (
    <Box maxWidth="4xl" margin="auto" padding={4}>
      <Flex justifyContent="space-between" alignItems="center" marginBottom={4}>
        <Heading as="h1" size="xl">Google Reviews Dashboard</Heading>
        <ButtonGroup isAttached variant="outline">
          <Button isActive colorScheme="blue">Weeks</Button>
          <Button onClick={() => handleToggleView('year')}>Year</Button>
        </ButtonGroup>
      </Flex>
      
      <Flex justifyContent="space-between" alignItems="center" marginBottom={4}>
        <Box width="300px">
          <Select
            isMulti
            options={availableWeeks}
            onChange={handleDateRangeChange}
            value={selectedDateRange}
            placeholder="Select date range..."
            isSearchable
            chakraStyles={{
              container: (provided) => ({
                ...provided,
                width: '100%',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                background: 'transparent',
                border: 'none',
                p: 0,
              }),
            }}
          />
        </Box>
      </Flex>
      
      <Box marginBottom={4}>
        <Select
          options={selectOptions}
          onChange={handleSelectChange}
          value={{ 
            value: selectedDataPoint.name, 
            label: `${selectedDataPoint.name} (${data.totalReviews || 0} reviews this period)`
          }}
          placeholder="Select a business..."
          isSearchable
          chakraStyles={{
            container: (provided) => ({
              ...provided,
              width: '100%',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              background: 'transparent',
              border: 'none',
              p: 0,
            }),
          }}
        />
      </Box>
      
      <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4} marginBottom={4}>
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg">Average Rating</Text>
          <Flex alignItems="center" fontSize="3xl" fontWeight="bold">
            {(data.averageRating || 0).toFixed(1)}
            <Icon as={StarIcon} color="yellow.400" marginLeft={2} />
          </Flex>
        </Box>
        
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg">Total Reviews</Text>
          <Text fontSize="3xl" fontWeight="bold">{data.totalReviews || 0}</Text>
        </Box>
        
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg">Comparison to Previous Period</Text>
          <Flex alignItems="center" fontSize="3xl" fontWeight="bold">
            {(data.comparisonToPrevious || 0) > 0 ? (
              <>
                <Icon as={TrendingUpIcon} color="green.500" marginRight={2} />
                +{(data.comparisonToPrevious || 0).toFixed(1)}
              </>
            ) : (
              <>
                <Icon as={TrendingDownIcon} color="red.500" marginRight={2} />
                {(data.comparisonToPrevious || 0).toFixed(1)}
              </>
            )}
          </Flex>
        </Box>
      </Grid>
      
      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4} marginBottom={4}>
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Daily Ratings Breakdown</Text>
          <Box height="250px">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={barChartData}>
                <XAxis dataKey={useDate ? "date" : "day"} tickFormatter={formatXAxis} />
                <YAxis />
                <Tooltip labelFormatter={formatXAxis} />
                <Legend />
                <Bar dataKey="positive" stackId="a" fill="#48BB78" />
                <Bar dataKey="neutral" stackId="a" fill="#ECC94B" />
                <Bar dataKey="negative" stackId="a" fill="#F56565" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Reviews per Day</Text>
          <Box height="250px">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={chartData}>
                <XAxis dataKey={useDate ? "date" : "day"} tickFormatter={formatXAxis} />
                <YAxis />
                <Tooltip labelFormatter={formatXAxis} />
                <Line type="monotone" dataKey="count" stroke="#805AD5" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Grid>
      
      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4} marginBottom={4}>
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Sentiment Breakdown</Text>
          <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
            <Text>Positive</Text>
            <Badge colorScheme="green">{sentimentBreakdown.positive || 0}</Badge>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
            <Text>Neutral</Text>
            <Badge colorScheme="gray">{sentimentBreakdown.neutral || 0}</Badge>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text>Negative</Text>
            <Badge colorScheme="red">{sentimentBreakdown.negative || 0}</Badge>
          </Flex>
        </Box>
        
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Common Keywords</Text>
          <Flex flexWrap="wrap" gap={2}>
            {Array.isArray(data.commonKeywords) && data.commonKeywords.length > 0 ? (
              data.commonKeywords.map((keyword, index) => (
                <Badge key={index} variant="outline">{keyword}</Badge>
              ))
            ) : (
              <Text>No keywords available</Text>
            )}
          </Flex>
        </Box>
      </Grid>
      {selectedDataPoint && selectedDataPoint.data && (
        <KeyPhrasesSection data={selectedDataPoint.data} />
      )}
    </Box>
  );
};

export default WeeklyReviewDashboard;