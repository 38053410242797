import { createIcon } from "@chakra-ui/icon"

export const TrendingUpIcon = createIcon({
  displayName: "TrendingUpIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"
    />
  ),
});

export const TrendingDownIcon = createIcon({
  displayName: "TrendingDownIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M16 18l2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z"
    />
  ),
});