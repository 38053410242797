import { Box, Button, Flex, FormControl, FormLabel, Input, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/week-in-review'); // redirect to /explore after successful login
    } catch (error) {
      console.error(error);
      // Handle errors here, such as displaying a message to the user
    }
  };

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center">
      <Box p={8} maxW="md" borderWidth={1} borderRadius="lg">
        <form onSubmit={handleLogin}>
          <VStack spacing={4}>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
            <Button type="submit" colorScheme="teal">Login</Button>
          </VStack>
        </form>
      </Box>
    </Flex>
  );
}

export default Login;