import React, { useState, useEffect, useCallback } from 'react';
import { Box, Flex, Grid, Heading, Text, Badge, Icon, Spinner, VStack, ButtonGroup, Button } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, LineChart, Line } from 'recharts';
import { StarIcon } from '@chakra-ui/icons';
import { TrendingUpIcon, TrendingDownIcon } from './icons';
import getYearData from '../services/yearService.js';
import { useNavigate } from 'react-router-dom';

const YearlyReviewDashboard = () => {
  const [yearData, setYearData] = useState([]);
  const [selectedDataPoint, setSelectedDataPoint] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getYearData();
      setYearData(data);
      
      const defaultBusiness = data.find(business => business.name === "Friends in Low Places Bar & Honky-Tonk") || data[0];
      setSelectedDataPoint(defaultBusiness);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSelectChange = (selectedOption) => {
    const selected = yearData.find(datapoint => datapoint.name === selectedOption.value);
    setSelectedDataPoint(selected);
  };

  const handleToggleView = (view) => {
    if (view === 'weeks') {
      navigate('/week-in-review');
    }
  };

  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <VStack spacing={4}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
          <Text fontSize="xl" fontWeight="bold">Loading Dashboard...</Text>
        </VStack>
      </Flex>
    );
  }

  if (!selectedDataPoint || !selectedDataPoint.data) {
    return <Box>No data available</Box>;
  }

  const { data } = selectedDataPoint;

  const selectOptions = yearData
    .sort((a, b) => (b.data?.totalReviews || 0) - (a.data?.totalReviews || 0))
    .map(business => ({
      value: business.name,
      label: `${business.name} (${business.data?.totalReviews || 0} reviews)`,
    }));

  const sentimentBreakdown = data.sentimentBreakdown || { positive: 0, neutral: 0, negative: 0 };

  const monthlyChartData = Object.entries(data.monthlyReviewCounts).map(([month, count]) => ({
    month,
    count,
    ...data.monthlyRatings[month]
  })).sort((a, b) => new Date(a.month) - new Date(b.month));

  return (
    <Box maxWidth="4xl" margin="auto" padding={4}>
      <Flex justifyContent="space-between" alignItems="center" marginBottom={4}>
        <Heading as="h1" size="xl">Google Reviews Dashboard</Heading>
        <ButtonGroup isAttached variant="outline">
          <Button onClick={() => handleToggleView('weeks')}>Weeks</Button>
          <Button isActive colorScheme="blue">Year</Button>
        </ButtonGroup>
      </Flex>
      
      <Box marginBottom={4}>
        <Select
          options={selectOptions}
          onChange={handleSelectChange}
          value={{ 
            value: selectedDataPoint.name, 
            label: `${selectedDataPoint.name} (${data.totalReviews || 0} reviews this year)`
          }}
          placeholder="Select a business..."
          isSearchable
          chakraStyles={{
            container: (provided) => ({
              ...provided,
              width: '100%',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              background: 'transparent',
              border: 'none',
              p: 0,
            }),
          }}
        />
      </Box>
      
      <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4} marginBottom={4}>
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg">Average Rating</Text>
          <Flex alignItems="center" fontSize="3xl" fontWeight="bold">
            {(data.averageRating || 0).toFixed(1)}
            <Icon as={StarIcon} color="yellow.400" marginLeft={2} />
          </Flex>
        </Box>
        
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg">Total Reviews</Text>
          <Text fontSize="3xl" fontWeight="bold">{data.totalReviews || 0}</Text>
        </Box>
        
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg">Monthly Average</Text>
          <Text fontSize="3xl" fontWeight="bold">
            {Math.round((data.totalReviews || 0) / 12)}
          </Text>
        </Box>
      </Grid>
      
      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4} marginBottom={4}>
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Monthly Ratings Breakdown</Text>
          <Box height="250px">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={monthlyChartData}>
                <XAxis dataKey="month" tickFormatter={(value) => new Date(value).toLocaleDateString('en-US', { month: 'short' })} />
                <YAxis />
                <Tooltip labelFormatter={(value) => new Date(value).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })} />
                <Legend />
                <Bar dataKey="positive" stackId="a" fill="#48BB78" />
                <Bar dataKey="neutral" stackId="a" fill="#ECC94B" />
                <Bar dataKey="negative" stackId="a" fill="#F56565" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Reviews per Month</Text>
          <Box height="250px">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={monthlyChartData}>
                <XAxis dataKey="month" tickFormatter={(value) => new Date(value).toLocaleDateString('en-US', { month: 'short' })} />
                <YAxis />
                <Tooltip labelFormatter={(value) => new Date(value).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })} />
                <Line type="monotone" dataKey="count" stroke="#805AD5" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Grid>
      
      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4} marginBottom={4}>
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Sentiment Breakdown</Text>
          <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
            <Text>Positive</Text>
            <Badge colorScheme="green">{sentimentBreakdown.positive || 0}</Badge>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
            <Text>Neutral</Text>
            <Badge colorScheme="gray">{sentimentBreakdown.neutral || 0}</Badge>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text>Negative</Text>
            <Badge colorScheme="red">{sentimentBreakdown.negative || 0}</Badge>
          </Flex>
        </Box>
        
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Common Keywords</Text>
          <Flex flexWrap="wrap" gap={2}>
            {Array.isArray(data.commonKeywords) && data.commonKeywords.length > 0 ? (
              data.commonKeywords.map((keyword, index) => (
                <Badge key={index} variant="outline">{keyword}</Badge>
              ))
            ) : (
              <Text>No keywords available</Text>
            )}
          </Flex>
        </Box>
      </Grid>
    </Box>
  );
};

export default YearlyReviewDashboard;