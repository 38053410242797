import React from 'react';
import { Box, Heading, List, ListItem, Text, Container } from '@chakra-ui/react';

const changelogData = [
    {
      version: '0.1',
      date: '2024-06-22',
      changes: [
        'Initial release.',
        'Implemented user authentication.',
        'Added basic dashboard functionality.',
      ],
    },
    {
      version: '0.2',
      date: '2024-06-24',
      changes: [
        'Week of 06-24 data capture.',
        'Metrics swapped to weekly temporarily.',
        'Added welcome dialogue.',
        'Added changelog.',
        'Page not found.'
      ],
    },
    {
      version: '0.3',
      date: '2024-06-30',
      changes: [
        'Navbar redesign.',
        'Per business week-in-review page.',
        'Common keywords.'
      ],
    }, 
    {
      version: '0.3.1',
      date: '2024-07-14',
      changes: [
        'Multi business view.',
        'Week select.',
        'Quality of life updates.',
      ],
    },    
    {
      version: '0.4.0',
      date: '2024-07-14',
      changes: [
        'Yearly view',
        'Multi Week select.',
        'Ranking by review count over period.',
      ],
    },
  ];

function ChangeLogEntry({ version, date, changes }) {
  return (
    <Box mb={5}>
      <Heading size="md">Version {version} - {date}</Heading>
      <List spacing={2} mt={2}>
        {changes.map((change, index) => (
          <ListItem key={index}>{change}</ListItem>
        ))}
      </List>
    </Box>
  );
}

const plannedUpdates = [
    {
      feature: 'Complete year data',
      timeframe: 'Jul 28',
    },
    {
      feature: 'Improved key terms (delayed)',
      timeframe: 'by Jul 14 (Jul 21) (Jul 28)',
    },
    {
      feature: 'Table View',
      timeframe: 'Jul 28',
    },
    {
        feature: 'User settings',
        timeframe: 'by Jul 21 (delayed) (Jul 28) (August 4)',
    }
  ];
  
  function PlannedUpdatesSection({ updates }) {
    return (
      <Box mt={10}>
        <Heading size="lg" mb={4}>Coming this week 🔜</Heading>
        <List spacing={3}>
          {updates.map((update, index) => (
            <ListItem key={index}>
              <Text fontWeight="bold">{update.feature}</Text>
              <Text>Expected: {update.timeframe}</Text>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }
  
  function ChangeLogParentComponent() {
    return (
      <Container maxW="container.md" mt={10} pb={10}>
        <Heading mb={4}>Changelog 📝</Heading>
        <ChangeLogEntry {...changelogData[4]} />
        <ChangeLogEntry {...changelogData[3]} />
        <ChangeLogEntry {...changelogData[2]} />
        <ChangeLogEntry {...changelogData[1]} />
        <ChangeLogEntry {...changelogData[0]} />
        <PlannedUpdatesSection updates={plannedUpdates} />
      </Container>
    );
  }

export default ChangeLogParentComponent;